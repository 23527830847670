/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

export default function ContactForm() {
  const data = useStaticQuery(graphql`
    {
      sanityContactpage {
        contactForm {
          title
          services
          budget
        }
      }
      sanitySiteSettings {
        email
      }
    }
  `);
  const { contactForm } = data.sanityContactpage;
  const { email } = data.sanitySiteSettings;

  const [subject, setSubject] = useState('');

  return (
    <>
      <section id="contact-form" data-scroll-section>
        <div className="wrapper">
          <form
            name="Contact form"
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            action="/success"
          >
            <input type="hidden" name="form-name" value="Contact form" />
            <input
              type="hidden"
              name="subject"
              value={`Enquiry from ${subject}`}
            />

            <p className="hidden">
              <label>
                Don't fill this out if you’re human:
                <input name="bot-field" />
              </label>
            </p>

            <div className="heading">
              <p className="big split-lines">{contactForm.title}</p>
              <p className="email element-reveal">
                Don't like forms? You can send us a{' '}
                <a href={`mailto:${email}`} className="link">
                  quick email
                </a>{' '}
                instead.
              </p>
            </div>

            <div className="two-inputs">
              <label htmlFor="name" className="form-input">
                <input type="text" name="name" id="name" required />
                <span>Your Name</span>
              </label>
              <label htmlFor="email" className="form-input">
                <input
                  type="email"
                  name="email"
                  id="email"
                  onChange={(e) => setSubject(e.target.value)}
                  required
                />
                <span>Your Email</span>
              </label>
            </div>

            <div className="form-group">
              <label htmlFor="how-can-we-help" className="label">
                How can we help you :
              </label>

              <select
                name="service"
                id="how-can-we-help"
                defaultValue="Landing page design"
              >
                <option value="Brand design">Brand design</option>
                <option value="Landing page design">Landing page design</option>
                <option value="Website design">Website design</option>
                <option value="Web design &amp; develoment">
                  Web design &amp; develoment
                </option>
                <option value="Branding &amp; Website">
                  Branding &amp; Website
                </option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="budget" className="label">
                Your budget (USD) :
              </label>

              <select name="budget" id="budget">
                <option value="5k to 10k">5k to 10k</option>
                <option value="10k to 20k">10k to 20k</option>
                <option value="20k+">20k+</option>
              </select>
            </div>

            <label htmlFor="message" className="form-group">
              <span className="label">Message :</span>
              <textarea name="message" id="message" />
            </label>

            <div className="cta">
              <button type="submit">Submit form</button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
}
