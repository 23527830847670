import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import ContactForm from '../components/ContactForm';
// import ClientReviews from '../components/ClientReviews';

export default function Contact({ data }) {
  const { hero } = data.sanityContactpage;
  const { seo } = data.sanityContactpage;

  return (
    <Layout contactBtn footer2 onLoadAnimations={[false, true]}>
      <SEO title={seo.title} description={seo.description} />

      <Hero
        title1="Let's get in"
        title2="Touch."
        tag={false}
        img={hero.asset.gatsbyImageData}
        alt={hero.alt}
      />
      <ContactForm />
      {/* <ClientReviews /> */}
    </Layout>
  );
}

export const query = graphql`
  {
    sanityContactpage {
      hero {
        alt
        asset {
          gatsbyImageData
        }
      }
      seo {
        title
        description
      }
    }
  }
`;
